import clsx from 'clsx';
import { memo } from 'react';

import { Image } from '../image/Image';

export type UserAvatarProps = {
  src?: string;
  alt?: string;
  className?: string;
};

export const UserAvatar = memo(function UserAvatar({
  className,
  src,
  alt,
}: UserAvatarProps) {
  return (
    <div
      className={clsx(
        'size-8 overflow-hidden rounded-full bg-darkGray object-cover',
        className,
      )}
    >
      {src ? (
        <Image alt={alt} src={src} />
      ) : (
        <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='16' cy='16' r='16' fill='#7A7A7A' />
          <circle cx='16' cy='10' r='6' fill='#242424' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.379 26.998C5.712 21.823 10.409 18 16 18c5.59 0 10.288 3.823 11.621 8.998A15.955 15.955 0 0 1 16 32a15.955 15.955 0 0 1-11.621-5.002z'
            fill='#242424'
          />
        </svg>
      )}
    </div>
  );
});
