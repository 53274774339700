export enum Rating {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
}

export enum ChatRole {
  User = 'user',
  System = 'system',
  Assistant = 'assistant',
  Tool = 'tool',
  Unknown = 'unknown',
}

export enum Role {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
}
