import { QueryClient } from '@tanstack/react-query';

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

/**
 * Chatbot-wide query keys used for cache invalidation
 * @see https://react-query.tanstack.com/guides/query-keys
 */
export const queryKeys = {
  chatAll: () => ['chat'] as const,
  chatDetail: (id: string | undefined) => ['chat', id] as const,
  threadsAll: () => ['threads'] as const,
  threadsList: (chatId: string | undefined) => ['threads', chatId] as const,
  faqs: () => ['faqs'] as const,
} as const;
