import { Button, Label } from '@utima/ui';
import { Form, Input, type TypedFormState } from '@utima/ui-informed';
import './sandbox.css';
import { useEffect, useState } from 'react';

export interface SandboxProps {
  renderApp: (chatId: string, options: any) => void;
}

type FormState = {
  chatId: string;
};

const HISTORY_KEY = 'sandbox-chat-ids';

export function Sandbox({ renderApp }: SandboxProps) {
  const [history, setHistory] = useState<string[]>(() =>
    JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]'),
  );

  // Create initialValues from query params
  const url = new URL(window.location.href);
  const initialValues = {
    chatId: url.searchParams.get('chatId') || '',
  };

  // Save history to localStorage
  useEffect(() => {
    localStorage.setItem(HISTORY_KEY, JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    if (initialValues.chatId) {
      renderApp(initialValues.chatId, {});
    }
  }, []);

  const handleSubmit = (form: TypedFormState<FormState>) => {
    const { values } = form;
    const { chatId } = values;

    renderApp(chatId, {});
    setHistory(prev => [...new Set([...prev, chatId])]);

    // Update URL with query param
    const url = new URL(window.location.href);
    url.searchParams.set('chatId', chatId);
    window.history.pushState({}, '', url.toString());
  };

  return (
    <div className='kanbu-chatbot-app'>
      <div className='m-12'>
        <div className='max-w-sm rounded-lg border border-border bg-white p-6 shadow-sm drop-shadow-sm'>
          <h2 className='mb-4 text-sm font-bold uppercase'>
            Sandbox Configuration
          </h2>
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            className='flex flex-col gap-4'
          >
            <Input required name='chatId' type='text' label='Chat ID:' />

            {history.length > 0 && (
              <div className='flex flex-col gap-2'>
                <Label>History:</Label>
                <div className='rounded-radius border border-border bg-gray-100 p-2 font-mono text-xs'>
                  {history.map(history => (
                    <div key={history}>{history}</div>
                  ))}
                </div>
              </div>
            )}

            <Button className='text-white' type='submit'>
              Create Chatbot
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Sandbox;
