import { Loader2, Send, Square } from 'lucide-react';
import {
  memo,
  useCallback,
  useDeferredValue,
  type ChangeEvent,
  type MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CharacterCounter } from '../characterCounter/CharacterCounter';
import { ResetConversation } from '../resetConversation/ResetConversation';

type InputBoxProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onAbort?: () => void;
  value: string;
  loading?: boolean;
  streaming?: boolean;
  initializing?: boolean;
  maxLength?: number;
};

export const InputBox = memo(function InputBox({
  onChange,
  onAbort,
  value,
  loading,
  streaming,
  initializing,
  maxLength = 300,
}: InputBoxProps) {
  const { t } = useTranslation();
  const deferredValue = useDeferredValue(value);

  /**
   * Handle the submission of the input box.
   * If the input box is loading, it will abort the submission.
   */
  const handleSubmit = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // Don't do anything if there is no onAbort function
      if (!onAbort) {
        return;
      }

      // Prevent submission and abort
      if (loading || streaming) {
        event.preventDefault();
        onAbort();
      }
    },
    [onAbort, loading, streaming],
  );

  return (
    <div className='flex flex-col gap-2'>
      <div className='relative'>
        <input
          type='text'
          className='placeholder:text-lightGrey relative flex h-12 w-full min-w-full appearance-none rounded-full bg-background pl-5 pr-20 text-base text-foreground outline-none transition-all focus:bg-background disabled:cursor-not-allowed disabled:opacity-75'
          placeholder={
            loading
              ? t('inputBox.waiting')
              : initializing
                ? t('inputBox.initializing')
                : t('inputBox.ready')
          }
          maxLength={maxLength}
          value={value}
          disabled={loading || initializing}
          onChange={onChange}
        />
        <div className='absolute inset-y-0 right-0 flex items-center pr-1'>
          <button
            type='submit'
            className='flex size-10 cursor-pointer items-center justify-center rounded-full bg-secondary transition-all hover:opacity-75 enabled:hover:scale-110 enabled:active:scale-100 disabled:cursor-auto disabled:opacity-75'
            disabled={
              ((loading || streaming) && !onAbort) ||
              initializing ||
              (deferredValue.length < 3 && !loading && !streaming)
            }
            onClick={handleSubmit}
          >
            {initializing && !loading && !streaming ? (
              <Loader2 className='text-grayDark2 size-5 animate-spin' />
            ) : (loading || streaming) && onAbort ? (
              <Square className='text-grayDark2 size-5' />
            ) : (
              <Send className='text-grayDark2 size-5' />
            )}
          </button>
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <ResetConversation onReset={onAbort} />
        <CharacterCounter max={maxLength} current={deferredValue?.length} />
      </div>
    </div>
  );
});
