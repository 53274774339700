import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

export interface ChatConfig {
  chatId: string;
}

export const ChatConfigContext = createContext<ChatConfig | null>(null);

interface ChatConfigProps extends PropsWithChildren<ChatConfig> {}

export function ChatConfigProvider({ children, chatId }: ChatConfigProps) {
  const contextValue = useMemo(() => {
    return { chatId };
  }, [chatId]);

  return (
    <ChatConfigContext.Provider value={contextValue}>
      {children}
    </ChatConfigContext.Provider>
  );
}

export function useChatConfig() {
  const context = useContext(ChatConfigContext);

  if (!context) {
    throw new Error('Missing ChatConfigContext.Provider in the tree');
  }

  return context;
}
