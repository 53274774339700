import { ChatRole } from '@kanbu/schema/enums';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
  type ChangeEvent,
  type FormEvent,
} from 'react';
import { v7 as uuid } from 'uuid';

import { processChatStream } from '@/lib/streamingUtils';
import {
  aiCoreApi,
  type CompletionJSONResponse,
} from '@/services/aiCoreClient';
import { queryKeys } from '@/services/queryClient';

import type { Message, UseChatParams, UseChatReturn } from './chatTypes';

const INITIAL_MESSAGES: Message[] = [];

/**
 * Helper function to create a new message object.
 */
export function createMessage(data: {
  message: string | Message;
  role: ChatRole;
}): Message {
  return typeof data.message === 'string'
    ? {
        role: data.role,
        text: data.message,
        createdAt: new Date().toISOString(),
        id: uuid(),
      }
    : data.message;
}

/**
 * Custom hook to handle chat logic, sending and receiving messages,
 * and managing chat history.
 */
export function useChat({
  initialInput = '',
  initialMessages = INITIAL_MESSAGES,
  threadId,
  chatId,
  stream,
  onMessage,
}: UseChatParams): UseChatReturn {
  const [_, startTransition] = useTransition();
  const [input, setInput] = useState(initialInput);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isInitialized, setIsInitialized] = useState(!!threadId);
  const abortController = useRef<null | AbortController>(null);

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...queryKeys.chatDetail(chatId), threadId],
    [chatId, threadId],
  );

  /**
   * Fetch message history, we also use this to store the chat
   * history in the state. This automatically handles cache invalidation
   * when thread is changed.
   */
  const { data: messages, isFetching: isInitializingMessages } = useQuery<
    Message[]
  >({
    queryKey: queryKey,
    queryFn: async () => {
      try {
        const data = await aiCoreApi.threads.findOne({
          params: {
            chatId,
            id: threadId!,
          },
        });

        /**
         * Since we use uuid v7 which is time based, we can sort the messages
         * by id to get the correct order.
         */
        return data.messages.length > 0
          ? data.messages.sort((a, b) => a.id.localeCompare(b.id))
          : initialMessages;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    placeholderData: INITIAL_MESSAGES,
    // We want to keep the messages in the cache forever
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!threadId,
  });

  /**
   * Abort the current request.
   */
  const abort = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);

  /**
   * Helper for calling completion API with stream option.
   */
  const callCompletionApi = useCallback(
    async (message: Message | string, stream: boolean = false) => {
      if (
        abortController.current?.signal &&
        !abortController.current.signal.aborted
      ) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      return aiCoreApi.chat.completion({
        stream,
        params: {
          chatId,
          threadId: threadId!,
        },
        json: {
          message: typeof message === 'string' ? message : message.text,
        },
        timeout: stream ? undefined : 120000,
        signal: abortController.current?.signal,
      });
    },
    [chatId, abortController, threadId],
  );

  useEffect(() => {
    setIsInitialized(!!threadId);
  }, [threadId]);

  /**
   * Insert new message to state, without sending it to the API.
   */
  interface InsertOptions {
    timeout?: number;
  }

  /**
   * Insert message into the chat state without sending it to the API.
   */
  const insert = useCallback(
    (
      message: Message | string,
      role: ChatRole = ChatRole.User,
      options?: InsertOptions,
    ) => {
      const { timeout = 0 } = options || {};

      if (timeout > 0) {
        setIsLoading(true);
      }

      (async () => {
        if (timeout > 0) {
          await new Promise(resolve => setTimeout(resolve, timeout));
        }

        queryClient.setQueryData(queryKey, (prev: Message[]) => [
          ...(prev ?? []),
          createMessage({
            message,
            role,
          }),
        ]);
        setIsLoading(false);
      })();
    },
    [queryClient, queryKey],
  );

  /**
   * Create new message, add it to the state and send it to the API.
   */
  const append = useCallback(
    async (message: Message | string) => {
      if (!threadId) {
        return console.error('Thread ID is required to send a message.');
      }

      startTransition(() => {
        insert(message);
        setIsLoading(true);
      });

      try {
        if (stream) {
          /**
           * Process chat stream, this function handles the streaming
           * response from the API and updates the chat state with new messages.
           *
           * FIXME since we are streaming text only response, we don't know the
           * message id, so we can't update the message in the state ->
           * this means that the feedback doesn't work currently.
           */
          await processChatStream({
            abortController: () => abortController.current,
            onUpdate: (newMessage: Message) => {
              /**
               * We need to update the message in the state, if it already exists.
               * Otherwise we just append the new message.
               */
              queryClient.setQueryData(queryKey, (prev: Message[]) => {
                // Disable loading state as soon as we get the first chunk
                startTransition(() => {
                  setIsLoading(false);
                  setIsStreaming(true);
                });

                const initialMessageIndex = prev.findIndex(
                  m => m.id === newMessage.id,
                );

                if (initialMessageIndex !== -1) {
                  return [
                    ...prev.slice(0, initialMessageIndex),
                    { ...newMessage },
                    ...prev.slice(initialMessageIndex + 1),
                  ];
                }

                return [...prev, newMessage];
              });
            },
            reader: (await callCompletionApi(
              message,
              true,
            )) as ReadableStreamDefaultReader<Uint8Array>,
          });
        } else {
          const response = (await callCompletionApi(
            message,
          )) as CompletionJSONResponse;

          const newMessage = createMessage({
            message: response.message,
            role: ChatRole.Assistant,
          });

          insert(newMessage);
          onMessage?.(newMessage);
        }
      } catch (error) {
        setError(error);
      } finally {
        startTransition(() => {
          setIsLoading(false);
          setIsStreaming(false);
        });
      }
    },
    [
      threadId,
      insert,
      stream,
      callCompletionApi,
      queryClient,
      queryKey,
      onMessage,
    ],
  );

  /**
   * Input/textarea on change handler, automatically updates input value.
   */
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    },
    [],
  );

  /**
   * Handle form submission. Sends message to the API and resets input field.
   */
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      startTransition(() => {
        append(input);
        setInput('');
      });
    },
    [append, input],
  );

  return {
    error,
    isLoading,
    isStreaming,
    isInitialized: !isInitializingMessages && isInitialized,
    messages: messages ?? INITIAL_MESSAGES,
    input,
    setInput,
    handleSubmit,
    handleInputChange,
    abort,
    append,
    insert,
  };
}
