import { setComponentOverrides } from '@utima/ui/overrides';

/**
 * @utima/ui component overrides
 */
setComponentOverrides({
  button: {
    button: 'hover:scale-105 active:scale-100 font-bold',
    variants: {
      variant: {
        secondary: 'bg-darkGray hover:bg-grey active:darkGray2',
      },
      size: {
        lg: '!rounded-xl',
      },
    },
  },
  input: {
    variants: {
      size: {
        lg: '!rounded-xl',
      },
    },
  },
  select: {
    label: 'text-black font-bold',
    variants: {
      size: {
        lg: '!rounded-xl',
      },
    },
  },
});
