import type defaultConfig from '../../config/default.json';

export type ConfigType = {
  debug: boolean;
};

const envConfig = __CHATBOT_CONFIG__ as any as typeof defaultConfig;

export const AppSettings = {
  ...envConfig,
  debug: document.cookie.includes('debug=true'),
};
