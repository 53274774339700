import { ChatRole } from '@kanbu/schema/enums';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useChatConfig } from '@/contexts/StoreContext';
import { createMessage, useChat } from '@/hooks/useChat';
import { useDelayedLoading } from '@/hooks/useDelayedLoading';
import { aiCoreApi } from '@/services/aiCoreClient';
import { queryKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { Faqs } from '../faqs/Faqs';
import { InputBox } from '../inputBox/InputBox';
import { MessageBox } from '../message/MessageBox';

export function Chat() {
  const { t } = useTranslation();
  const { chatId } = useChatConfig();
  const [threadId, setThreadId, opened, setUnread] = useBoundStore(state => [
    state.threadId,
    state.setThreadId,
    state.opened,
    state.setUnread,
  ]);

  const hasFAQ = false;

  /**
   * Fetch user threads and create a new thread if it does not exist.
   * This will be used to store the chat history.
   */
  const threadsQuery = useQuery({
    queryKey: queryKeys.threadsList(chatId),
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: async () => {
      const data = await aiCoreApi.threads.findAll({
        params: {
          chatId,
        },
        /**
         * We don't want to throw an error if the thread is not found
         * This will allow us to create a new thread.
         */
        throwHttpErrors: false,
      });

      if (
        typeof data === 'object' &&
        'statusCode' in data &&
        data.statusCode === 404
      ) {
        const newThread = await aiCoreApi.threads.create({
          params: {
            chatId,
          },
        });

        return [newThread];
      }

      return data;
    },
    enabled: opened,
  });

  /**
   * Set the thread id to the first thread in the list.
   * This will be used to store the chat history.
   */
  useEffect(() => {
    if (Array.isArray(threadsQuery.data) && threadsQuery.data.length > 0) {
      setThreadId(threadsQuery.data[0].id);
    }
  }, [threadsQuery.data, setThreadId]);

  /**
   * Always set unread, this should be cleaned up when clicking close button.
   */
  const handleMessage = useCallback(() => {
    setUnread(1);
  }, [setUnread]);

  /**
   * This handles the chat logic, it fetches the messages from the API
   * and sends new messages to the API.
   */
  const {
    input,
    handleInputChange,
    messages,
    handleSubmit,
    isLoading,
    isStreaming,
    isInitialized,
    abort,
    insert,
  } = useChat({
    threadId,
    chatId,
    stream: false,
    initialMessages: [
      createMessage({
        message: t('messageBox.initial'),
        role: ChatRole.Assistant,
      }),
    ],
    onMessage: handleMessage,
  });

  const throttledIsLoading = useDelayedLoading(isLoading, 750);

  return (
    <form className='relative flex h-full flex-col' onSubmit={handleSubmit}>
      {/*Uncomment if you need it*/}
      {/* <ActionBar /> */}
      <div className='flex grow flex-col-reverse overflow-y-auto px-5 py-4'>
        <div className='flex grow flex-col gap-6 [&>*:first-child]:mt-auto'>
          <AnimatePresence mode='popLayout'>
            {messages.map(({ id, role, text, createdAt, feedback }) => (
              <motion.div
                key={id}
                initial={{ y: 15, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <MessageBox
                  id={id}
                  role={role}
                  text={text}
                  createdAt={createdAt}
                  feedbackId={feedback as string}
                />
              </motion.div>
            ))}
            {throttledIsLoading && (
              <motion.div
                initial={{ y: 15, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ y: 15, opacity: 0 }}
              >
                <MessageBox key='loader' role={ChatRole.Assistant} isTyping />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div
        className={cn(
          'shrink-0 bg-backgroundSecondary px-5 pb-4',
          !hasFAQ && 'pt-4',
        )}
      >
        {hasFAQ && <Faqs insert={insert} />}
        <InputBox
          value={input}
          onChange={handleInputChange}
          onAbort={abort}
          streaming={isStreaming}
          loading={isLoading}
          initializing={!isInitialized}
        />
      </div>
    </form>
  );
}
