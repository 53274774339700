import { ChatRole } from '@kanbu/schema/enums';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import {
  useEffect,
  useState,
  type ComponentProps,
  type ComponentPropsWithoutRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import avatarImg from '@/assets/images/avatar.jpg';
import { preprocessMarkdown } from '@/lib/utils';

import { ActivityIndicator } from './ActivityIndicator';
import { TimeAgo } from './TimeAgo';
import { UserAvatar } from './UserAvatar';
import { Feedback } from '../feedback/Feedback';
// import { Timer } from '../timer/Timer';

interface MessageBoxProps
  extends ComponentPropsWithoutRef<'div'>,
    Omit<ComponentProps<'div'>, 'role'> {
  id?: string;
  text?: string;
  feedbackId?: string;
  isTyping?: boolean;
  role: ChatRole;
  createdAt?: string;
}

export function MessageBox({
  text,
  className,
  isTyping = false,
  role = ChatRole.User,
  createdAt = new Date().toISOString(),
  id,
  feedbackId,
  ...restProps
}: MessageBoxProps) {
  const [textMarked, setTextMarked] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    async function load() {
      if (!text) {
        return;
      }

      try {
        const textMarked = await marked(DOMPurify.sanitize(text));
        const textMarkedRemove = preprocessMarkdown(textMarked);
        setTextMarked(textMarkedRemove);
      } catch (error) {
        console.error(error);
      }
    }
    load();
  }, [text]);

  return (
    <div className={clsx('relative', className)} {...restProps}>
      <span
        className={clsx(
          'block pb-1 text-sm font-bold leading-6 text-foreground',
          {
            ['text-right']: role === ChatRole.User,
            ['text-left']: role === ChatRole.Assistant,
          },
        )}
      >
        {role === ChatRole.User ? t('messageBox.user') : t('messageBox.system')}
      </span>

      <div
        className={clsx(
          'flex items-end gap-2',
          {
            ['flex-row']: role === ChatRole.Assistant,
            ['flex-row-reverse']: role === ChatRole.User,
          },
          className,
        )}
      >
        <UserAvatar src={role === ChatRole.Assistant ? avatarImg : undefined} />
        <div
          className={clsx(
            'flex max-w-[75%] rounded-2xl px-3.5 py-3 text-darkGray',
            {
              ['rounded-br-none ml-auto bg-secondary']: role === ChatRole.User,
              ['rounded-bl-none bg-backgroundSecondary']:
                role === ChatRole.Assistant,
            },
          )}
        >
          {isTyping ? (
            <ActivityIndicator />
          ) : (
            <div
              style={{ overflowWrap: 'anywhere' }}
              className='kanbu-chat__message flex break-before-auto flex-col gap-2'
              dangerouslySetInnerHTML={{
                __html: textMarked,
              }}
            />
          )}
        </div>
      </div>

      {!isTyping && (
        <TimeAgo
          className={clsx({
            ['text-right']: role === ChatRole.User,
            ['text-left']: role === ChatRole.Assistant,
          })}
          date={createdAt}
        />
      )}
      {role === ChatRole.Assistant && !isTyping && <Feedback messageId={id} />}
    </div>
  );
}
