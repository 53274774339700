import '@/services/i18n';

import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { FormTranslationsContext } from '@utima/ui-informed';
import { lazy, StrictMode, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './app.css?inline';
import { AppSettings } from './constants/AppSettings';
import { ChatConfigProvider } from './contexts/StoreContext';
import { defaultQueryClient } from './services/queryClient';
import { Widget } from './Widget';

import { version } from '../package.json';

const ReactQueryDevtools = lazy(() =>
  import('@tanstack/react-query-devtools').then(res => ({
    default: res.ReactQueryDevtools,
  })),
);

type AppProps = {
  chat: string | null | undefined;
  themeConfig?: any;
};

export function App({ chat, themeConfig }: AppProps) {
  const { t } = useTranslation();
  const translations = useMemo(
    () => ({
      labels: {
        optional: t('glossary.optional'),
      },
      errors: {
        required: t('glossary.required'),
      },
    }),
    [t],
  );

  /**
   * Override chat id definition for local development,
   * when available in config.
   */
  if (!chat && AppSettings.app.chatId) {
    chat = AppSettings.app.chatId;
  }

  if (!chat) {
    // TODO should probably provided nicer error message and log this
    return <div>{t('errors.missingChatId')}</div>;
  }

  return (
    <StrictMode>
      <ErrorBoundary>
        <ChatConfigProvider chatId={chat}>
          <FormTranslationsContext.Provider value={translations}>
            <QueryClientProvider client={defaultQueryClient}>
              {/* .kanbu-chatbot-app is used for tailwind scoping */}
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <div className='kanbu-chatbot-app' data-version={version}>
                <div className='relative z-[999999] font-sans text-base'>
                  {/* Handles style injection to shadow dom in Web Component */}
                  <style dangerouslySetInnerHTML={{ __html: styles }} />

                  {/* Chatbot widget */}
                  <Widget />
                </div>
              </div>
              {AppSettings.debug && (
                <Suspense fallback={null}>
                  <ReactQueryDevtools buttonPosition='bottom-left' />
                </Suspense>
              )}
            </QueryClientProvider>
          </FormTranslationsContext.Provider>
        </ChatConfigProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}
