import clsx from 'clsx';
import {
  memo,
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface TimeAgoProps extends ComponentPropsWithoutRef<'div'> {
  date: string;
}

/**
 * Calculate the time difference between the
 * provided time and the current time. It also
 * updates the time every minute.
 */
function useTimeAgo(date: string, updateInterval = 60000) {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [updateInterval]);

  const providedTime = new Date(date);
  providedTime.setTime(providedTime.getTime());

  const timeDifferenceInSeconds = Math.floor(
    (Number(currentTime) - Number(providedTime)) / 1000,
  );

  if (timeDifferenceInSeconds < 60) {
    return t('timer.sendNow');
  }

  if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);

    return `${t('timer.sendBefore')} ${minutes} ${t('timer.minutes', { count: minutes })}`;
  }

  if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);

    return `${t('timer.sendBefore')} ${hours} ${
      hours === 1
        ? t('timer.hour', { count: hours })
        : t('timer.hours', { count: hours })
    }`;
  }

  const days = Math.floor(timeDifferenceInSeconds / 86400);

  return `${t('timer.sendBefore')} ${days} ${
    days === 1
      ? t('timer.day', { count: days })
      : t('timer.days', { count: days })
  }`;
}

export const TimeAgo = memo(function TimeAgo({
  date,
  className,
  ...restProps
}: TimeAgoProps) {
  const timeAgo = useTimeAgo(date, 60000);

  return (
    <div
      className={clsx('pt-2 text-xs text-foreground', className)}
      {...restProps}
    >
      {timeAgo}
    </div>
  );
});
