import { X } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import logoImg from '@/assets/images/logo-black.svg';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';

export const TopBar = memo(function TopBar() {
  const { t } = useTranslation();
  const [close, setUnread] = useBoundStore(state => [
    state.close,
    state.setUnread,
  ]);

  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  return (
    <div className='kanbu-yellow-gradient sticky top-0 flex h-14 flex-row items-center justify-between px-4'>
      <div className='flex items-center gap-3'>
        {/*TODO - Switch white/black logo based on background color*/}
        <Image className='h-5' src={logoImg} />
        <div className='inline-flex rounded bg-foreground px-1 py-px text-xs text-background'>
          {t('topBar.beta')}
        </div>
      </div>
      <div className='flex gap-4'>
        <button
          type='button'
          className='hover:text-lightGrey flex items-center justify-center text-darkGray transition-transform hover:scale-110 active:scale-100'
          onClick={handleClose}
        >
          <X className='size-6' />
        </button>
      </div>
    </div>
  );
});
