import { ChatRole } from '@kanbu/schema/enums';
import { v7 as uuid } from 'uuid';

import type { Message } from '@/hooks/chatTypes';

/**
 * Creates a new stream chunk decoder. Currently we only support
 * text streaming using TextDecoder.
 */
export function createChunkDecoder() {
  const decoder = new TextDecoder();

  return (chunk: Uint8Array | undefined): string => {
    if (!chunk) {
      return '';
    }

    return decoder.decode(chunk, { stream: true });
  };
}

/**
 * Helper for processing chat text stream data. This function will
 * read the stream and update the chat messages based on the onUpdate
 * method provided.
 */
export async function processChatStream(args: {
  reader: ReadableStreamDefaultReader<Uint8Array>;
  onUpdate: (newMessage: Message) => void;
  abortController: () => null | AbortController;
}) {
  const { reader, onUpdate, abortController } = args;
  const decoder = createChunkDecoder();

  // Create a new message object to store the result
  const resultMessage: Message = {
    id: uuid(),
    createdAt: new Date().toISOString(),
    role: ChatRole.Assistant,
    text: '',
  };

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    resultMessage.text += decoder(value);

    // note: creating a new message object is required for Solid.js streaming
    onUpdate({ ...resultMessage });

    // The request has been aborted, stop reading the stream.
    if (abortController?.() === null) {
      reader.cancel();
      break;
    }
  }

  return resultMessage;
}
