import type {
  ChatMessageType,
  ChatUserType,
  FeedbackType,
  ThreadType,
} from '@kanbu/schema';
import type { Rating } from '@kanbu/schema/enums';
import { captureException, withScope } from '@sentry/react';
import ky, { type Options } from 'ky';

import type { Faq } from '@/components/faqs/Faqs';
import { AppSettings } from '@/constants/AppSettings';
import { useBoundStore } from '@/store/store';

type OptionsWithParams<T = Record<string, unknown>> = Options & { params: T };
// type OptionsWithBody<T = unknown> = Options & {
//   json: T;
// };

type OptionsWithBodyParams<
  TParams extends Record<string, unknown>,
  TJson = unknown,
> = Options & {
  params: TParams;
  json: TJson;
};

export type ErrorResponse = {
  statusCode: number;
  error: string;
  message: string;
};

/**
 * Strips the `params` property from the options object.
 */
export function stripParams(options: OptionsWithParams) {
  const { params, ...rest } = options;

  return rest;
}

export type CompletionJSONResponse = {
  message: ChatMessageType;
};

/**
 * ky API client instance for communicating with the
 * AI core backend.
 */
export const aiCoreClient = ky.create({
  prefixUrl: AppSettings.api.baseURL,
  hooks: {
    beforeRequest: [
      request => {
        const { accessToken } = useBoundStore.getState();

        if (!accessToken) {
          return request;
        }

        request.headers.set(
          'Authorization',
          `Bearer ${useBoundStore.getState().accessToken}`,
        );

        return request;
      },
    ],
    beforeError: [
      error => {
        withScope(scope => {
          scope.setExtras({ error });
          captureException(error);
        });

        if (import.meta.env.DEV) {
          console.error(JSON.stringify(error, null, 2));
        }

        return error;
      },
    ],
  },
});

/**
 * ky API client instance for communicating with the
 * AI core backend.
 * @deprecated
 */
export const adminApiClient = ky.create({
  prefixUrl: AppSettings.api.adminApiURL,
  hooks: {
    beforeError: [
      error => {
        withScope(scope => {
          scope.setExtras({ error });
          captureException(error);
        });

        if (import.meta.env.DEV) {
          console.error(JSON.stringify(error, null, 2));
        }

        return error;
      },
    ],
  },
});

/**
 * Simple service for communicating with the AI core backend.
 */
export const aiCoreApi = {
  /**
   * Authenticate a local user, returns the user details.
   */
  auth: {
    local: async (
      args?: Options,
    ): Promise<{
      user: ChatUserType;
      token: string;
    }> =>
      aiCoreClient
        .post(`auth/local`, {
          ...args,
        })
        .json(),
  },
  threads: {
    /**
     * Get chat thread including message history (populated on BE).
     */
    findOne: async (
      args: OptionsWithParams<{ chatId: string; id: string }>,
    ): Promise<
      Omit<ThreadType, 'messages'> & {
        messages: ChatMessageType[];
      }
    > =>
      aiCoreClient
        .get(`chats/${args.params.chatId}/threads/${args.params.id}`, {
          ...stripParams(args),
        })
        .json(),
    /**
     * Get user threads for the chat.
     */
    findAll: async (
      args: OptionsWithParams<{ chatId: string }>,
    ): Promise<ThreadType[]> =>
      aiCoreClient
        .get(`chats/${args.params.chatId}/threads`, {
          ...stripParams(args),
        })
        .json(),
    /**
     * Create a new thread for the user and current chat.
     */
    create: async (
      args: OptionsWithParams<{ chatId: string }>,
    ): Promise<ThreadType> =>
      aiCoreClient
        .post(`chats/${args.params.chatId}/threads`, {
          ...stripParams(args),
        })
        .json(),
  },
  chat: {
    /**
     * Sends user prompt to the AI core backend and returns the response.
     */
    completion: async (
      args: OptionsWithBodyParams<
        {
          chatId: string;
          threadId: string;
        },
        {
          message: string;
        }
      > & { stream?: boolean },
    ): Promise<
      ReadableStreamDefaultReader<Uint8Array> | CompletionJSONResponse
    > => {
      const res = await aiCoreClient.post(
        `chats/${args.params.chatId}/threads/${args.params.threadId}/completion`,
        {
          ...stripParams(args),
          searchParams: new URLSearchParams({
            stream: args.stream ? 'true' : 'false',
          }),
        },
      );

      if (args.stream) {
        return res.body?.getReader() as ReadableStreamDefaultReader<Uint8Array>;
      }

      return res.json() as Promise<CompletionJSONResponse>;
    },
  },
  feedback: {
    /**
     * Create new feedback for given message.
     */
    create: async (
      args: OptionsWithBodyParams<
        { messageId: string },
        {
          rating: Rating;
          text?: string;
        }
      >,
    ): Promise<FeedbackType> =>
      aiCoreClient
        .post(`messages/${args.params.messageId}/feedback`, {
          ...stripParams(args),
        })
        .json(),
  },
};

/**
 * Simple service for communicating with the ADMIN api backend.
 * @deprecated
 */
export const adminApi = {
  /**
   * Fetch active FAQs.
   */
  fetchActiveFaqs: async (options?: Options): Promise<Faq[]> =>
    adminApiClient
      .get(`api/faqs-active`, {
        ...options,
      })
      .json(),
  /**
   * Increment FAQ by ID.
   */
  incrementFaqs: async (id: string, options?: Options): Promise<Faq> =>
    adminApiClient
      .post(`api/faqs-clicked`, {
        json: { faqId: id },
      })
      .json(),
};
