import './uiOverrides';
import { init } from '@sentry/react';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { AppSettings } from './constants/AppSettings';

init({
  dsn: AppSettings.sentry.dsn,
  integrations: [],
});

class ChatbotWebComponent extends HTMLElement {
  mountPoint: HTMLDivElement;

  constructor() {
    super();

    // Preload google webfonts
    // this.loadGoogleFonts()

    const shadowRoot = this.attachShadow({ mode: 'open' });
    const mountPoint = document.createElement('div');

    shadowRoot.append(mountPoint);
    this.mountPoint = mountPoint;
  }

  connectedCallback() {
    const root = createRoot(this.mountPoint);
    root.render(
      createElement(App, {
        chat: this.getAttribute('chat'),
      }),
    );
  }

  loadGoogleFonts() {
    const preloadGStatic = document.createElement('link');
    preloadGStatic.rel = 'preconnect';
    preloadGStatic.href = 'https://fonts.gstatic.com';
    preloadGStatic.crossOrigin = 'anonymous';

    const preloadGoogleApis = document.createElement('link');
    preloadGoogleApis.rel = 'preconnect';
    preloadGoogleApis.href = 'https://fonts.googleapis.com';

    const robotoFont = document.createElement('link');
    robotoFont.rel = 'stylesheet';
    robotoFont.href =
      'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap';

    // Append to document head
    document.head.append(preloadGStatic);
    document.head.append(preloadGoogleApis);
    document.head.append(robotoFont);
  }
}

customElements.define('kanbu-chatbot', ChatbotWebComponent);
